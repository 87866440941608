import React, { useContext, useState } from "react";
import {  useNavigate } from "react-router";
import { AppSettings } from "./App";
import "./login.css";
function Login() {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  function Auth() {
    if (user === "admin" && password === "admin@123") {
      window.sessionStorage.setItem("auth", true);
      navigate("/active");
    } else {
      setMessage("Invalid ID or Password");
    }
  }
  return (
    <div className="outer">
      <button data-text="Awesome" class="buttonT">
        <span class="actual-text">&nbsp;PHANTOM&nbsp;EXPRESS&nbsp;</span>
        <span class="hover-text" aria-hidden="true">
          &nbsp;PHANTOM&nbsp;EXPRESS&nbsp;
        </span>
      </button>
      <div class="content">
        {message && <p className="text-danger">{message}</p>}
        <div class="text">Login</div>
        <form action="#">
          <div class="field">
            <input
              required=""
              type="text"
              class="input"
              onChange={(e) => setUser(e.target.value)}
            />
            <span class="bi bi-person-circle span h3 ps-2"></span>
            <label class="label">Email or Phone</label>
          </div>
          <div class="field">
            <input
              required=""
              type="password"
              class="input"
              onChange={(e) => setPassword(e.target.value)}
            />
            <span class="bi bi bi-key-fill h3 ps-2 span"></span>
            <label class="label">Password</label>
          </div>

          <button class="button mt-3" onClick={Auth}>
            Sign in
          </button>
          {/* <div class="sign-up">
              Not a member?
              <a href="#">signup now</a>
           </div> */}
        </form>
      </div>
    </div>
  );
}
export default Login;
