import React,{ useContext, useEffect, useState } from "react";
import Card from "./Card";
import { AppSettings } from "./App";



function Completed() {
 const context=useContext(AppSettings)

  return (
        <div className="container mb-5">
        <div className=" row-cols-lg-3 m-1 row justify-content-between">
            {context.completed && context.completed.map(item=> <Card id={item.id} lrNum={item.lr_no} vehicleNumber={item.vehiclename} customer={item.partyname} dateTime={item.pickup_datetime} tripDuration={item.expected_trip_duration} pickup={item.pickup} delivery={item.delivery} hub={item.hub} color={item.active==5?'danger':'success'} code='3' />)}
        </div>
      </div>
  );
}

export default Completed;