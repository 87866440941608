import React,{ useContext, useEffect, useState } from "react";
import Card from "./Card";
import { AppSettings } from "./App";



function Snoozed() {
 const context=useContext(AppSettings)
  
  return (
        <div className="container mb-5">
        <div className=" row-cols-lg-4 m-1 row justify-content-between">
          {context.snoozedArr && context.snoozedArr.map((item) => (
             item.id && <Card id={item.id} lrNum={item.lr_no} vehicleNumber={item.vehiclename} customer={item.partyname} dateTime={item.pickup_datetime} tripDuration={item.expected_trip_duration} pickup={item.pickup} delivery={item.delivery} hub={item.hub} stat={item.booking_status} code='2' color='warning'/>
          ))}
        </div>
      </div>
  );
}

export default Snoozed;