import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./Navbar";
import React from "react";
import Active from "./Active";
import Snoozed from "./Snoozed";
import Completed from "./Completed";
import PrivateRoute from "./PrivateRoute";
import {
  Routes,
  Route,
  Link,
  useLocation
} from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import Login from "./Login";
import VehicleDashboard from "./VehicleDashboard";
const endpoint = 'http://apiv2.phantom2me.com';
const socket = socketIOClient(endpoint);
export const AppSettings = React.createContext();
function App() {
  const location = useLocation();
  const [arr, setArr] = useState();
  const [snoozedArr, setSnoozedArr] = useState([]);
  const [completed,setCompleted]=useState([])
  const[counting,setCounting]=useState({})
  const[bookingStatus,setBookinStatus]=useState({})
  const[vehicleType,setVehicleType]=useState({})
  const [yellowOne,setYellowOne]=useState([]);
  const[geoCent,setGeoCent]=useState()
  const[auth,setAuth]=useState(false)
  const lengthFinder=()=>{
     fetch('http://apiv2.phantom2me.com/imagelength')
        .then(response => response.json())
        .then(data => {
         data.map(item=>setCounting(state=>({...state,...item})))
        })
  }
  const values = {
    arr,
    setArr,
    snoozedArr,
    setSnoozedArr,
    completed,
    setCompleted,
    counting,setCounting,
    bookingStatus,
    lengthFinder,
    vehicleType,
    setVehicleType,
    yellowOne,
    auth,
    setAuth,
    geoCent,
    setGeoCent,
    setYellowOne,
   };
 
  const isLoginPage = location.pathname === '/';
  const isVehicle = location.pathname === '/vehicle';

  async function fetcher(){
    let a= await fetch('http://apiv2.phantom2me.com/getcompleted')
    let b= await a.json();
    let c=await fetch('http://apiv2.phantom2me.com/data', {headers: {
      'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
    }})
    let d=await c.json();
    setCompleted([...b,...(d.filter(item=>item.active==4 ||item.active==5))])

    await fetch('http://apiv2.phantom2me.com/data', {headers: {
      'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
    }})
      .then(response => response.json())
      .then(result =>{
        fetch('http://apiv2.phantom2me.com/getsnoozed')
    .then(res=>res.json())
    .then(data=>{setSnoozedArr(data);
      setArr(result.filter(item =>{
        return  item.active!=4 && item.active!=5 && !data.some(obj => obj.id == item.id) && !b.some(obj => obj.id == item.id) ;
      }));
    })
         })
      .catch(error => console.log('error', error));

      fetch('http://apiv2.phantom2me.com/vehicletype', {headers: {
        'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
      }})
      .then(res=>res.json())
      .then(data=>data.map(item=>setVehicleType(state=>({...state,[item.id]:item.vehicletypename}))))
   }



     useEffect(()=>{
      fetch('http://apiv2.phantom2me.com/tripstatus', {headers: {
        'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
      }})
      .then(res=>res.json())
      .then(data=>data.map(item=>setBookinStatus(state=>({...state,[item.id]:item.booking_status}))))
      socket.on('statusUpdate',(data)=>
      data.map(item=>setBookinStatus(state=>({...state,[item.id]:item.booking_status})))
        )
     },[])
    //  useEffect(()=>{
    //   fetch('http://apiv2.phantom2me.com/vehicletype')
    //   .then(res=>res.json())
    //   .then(data=>data.map(item=>setVehicleType(state=>({...state,[item.id]:item.vehicletypename}))))
    //  },[])
  useEffect(() => {
    // Listen for new data
   fetcher();
   lengthFinder();
  }, []);

  return (
    <AppSettings.Provider value={values}>
      <div className="container-fluid row m-0 p-0" >
      {!isLoginPage && <Navbar />}
        <div className="container row mb-4 ms-auto">
          <Routes>
                <Route path="/" element={<Login/>}></Route>
               
                 <Route exact path='/active' element={<PrivateRoute isAuthenticated={sessionStorage.getItem('auth')?true:false}><Active/></PrivateRoute>}></Route>
                 <Route exact path='/snoozed' element={<PrivateRoute isAuthenticated={sessionStorage.getItem('auth')}><Snoozed/></PrivateRoute>}></Route>
                 <Route exact path='/completed' element={<PrivateRoute isAuthenticated={sessionStorage.getItem('auth')}>< Completed /></PrivateRoute>}></Route>
                 <Route exact path='/vehicle' element={<PrivateRoute isAuthenticated={sessionStorage.getItem('auth')}>< VehicleDashboard /></PrivateRoute>}></Route>

          </Routes>
          </div>
          {(!isLoginPage && !isVehicle) && <footer className="text-center text-lg-start text-muted footer" style={{bottom:'0',position:'fixed'}}>
  <section className="d-flex justify-content-center justify-content-lg-between p-1 border-bottom">
    <div className="col-lg-3 mb-md-0">
      
    <ul className="list-unstyled mb-0 d-flex justify-center btn-group">
       
           <li className="col-md-5 me-2">
                
              <button type="button" className="btn btn-success position-relative " >
              <Link className="text-white btn" to="/active">Active</Link>
  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {arr && arr.length}
  </span>
</button>
              </li>
              <li className="col-md-5 me-2">
              <button type="button" className="btn btn-warning position-relative">
              <Link className="text-white btn" to="/snoozed">Snoozed</Link>
  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {snoozedArr && snoozedArr.length}
  </span>
</button>
              </li>
              <li className="col-md-5 me-2">
              <button type="button" className="btn btn-secondary position-relative">
              <Link className="text-white btn" to="/completed">Completed/Cancelled</Link>
  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {completed.length}
  </span>
</button>
              </li>
            
            </ul>
    </div>

  </section>
  
</footer>}
      </div>
      
    </AppSettings.Provider>
  );
}

export default App;
