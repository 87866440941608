import React, { useContext, useEffect, useState } from "react";
import Map from "./Map";
import { AppSettings } from "./App";
function VehicleDashboard(){
    const[vehicles,setVehicles]=useState([])
    const context=useContext(AppSettings)
    const[current,setCurrent]=useState([])
    const[onTrip,setOnTrip]=useState([])
    const[tripPlanned,setTripPlanned]=useState([])
    const[idle,setIdle]=useState([])
// const [Gmarkers,setGMarkers]=useState([]);
// const [Ymarkers,setYMarkers]=useState([]);
const [Rmarkers,setRMarkers]=useState([]);
const [VehiByDis,setVehiByDis]=useState([])
const [displayDis,setDisplayDis]=useState(false)
function fetchingData(){
  fetch('http://apiv2.phantom2me.com/vehicles', {headers: {
            'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
          }})
          .then(res=>res.json())
          .then(data=>{setVehicles(data)
            setCurrent(data)
            setIdle(data.filter(item=>item.trip_status==3))
            setOnTrip(data.filter(item=>item.trip_status==1))
            setTripPlanned(data.filter(item=>item.trip_status==2))
                 })
        fetch(`https://pullapi-s2.track360.co.in/api/v1/auth/pull_api?username=skyways&password=SkyHigh@110037110038`)
        .then(response => response.json())
        .then(result => setRMarkers(result.data.map(item=>{return ({lat:parseFloat(item.latitude),lng:parseFloat(item.longitude),vehiclename:item.name})})))
        .catch(error => console.error(error));
}
    useEffect(()=>{
        fetchingData();
    },[])
    
  const distanceOrganizer=(latlng,radius)=>{
    setDisplayDis(true)
    fetch('http://apiv2.phantom2me.com/roadcastToken', {headers: {
      'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
    }})
    .then(res=>res.json())
    .then(data=>{
      fetch(`https://prod-s2.track360.net.in/api/v1/auth/nearby_vehicles?latitude=${latlng.lat}&longitude=${latlng.lng}&nearby_meters=${radius}`,{headers: {
        'Authorization': data[0].token
      }})
      .then(res=>res.json())
      .then(result=>setVehiByDis(result.data.sort((a,b)=>a.distance-b.distance).filter(item1 => vehicles.some(item2 => item1.vehiclename === item2.device_name))))
    })
  }
  
    return(
        <div className="container row">
            <div className="col-md-3 " style={{height:"80vh"}}>
            <button className="btn btn-success  col-md-4 m-1" onClick={()=>setCurrent(onTrip)}>On Trip</button>
            <button className="btn btn-warning col-md-5 m-1" onClick={()=>setCurrent(tripPlanned)}>Trip Planned</button>
            <button className="btn btn-danger col-md-2 m-1" onClick={()=>setCurrent(idle)}>Idle</button>
            <div className="container col-md-12 overflow-auto m-2" style={{height:"inherit"}}>
            {displayDis && VehiByDis.map(item=><button className='text-white fw-bold border-0 text-center p-1 m-1 bg-orange rounded col-md-10'>{item.device_name}</button>)}

               {!displayDis && current.map(item=><button className={`text-dark fw-bold border-0 text-center p-1 m-1 bg-${item.trip_status==1?'success':(item.trip_status==2?'warning':'danger')} rounded col-md-10`} value={item.vehiclename} onClick={(e)=>context.setGeoCent(...([...Rmarkers].filter(item=>item.vehiclename===e.target.value)) )}>{item.vehiclename}</button>)}
            </div>
            </div>
           
            <div className="col-md-9 border"  style={{height:"100%"}}>
                           
            <Map red={Rmarkers} centerVehi={context.geoCent} distanceOrganizer={distanceOrganizer} />
            </div>

        </div>
    )
}
export default VehicleDashboard;