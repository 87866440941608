import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
     
      <nav className="navbar navbar-expand-lg  p-0 m-0" >
  <div className="container-fluid ">
  
        <div className={isOpen ? 'open oppenned' : 'open'} onClick={toggleMenu}>
        <span className="cls" ></span>
        
        <span>
          <ul className="sub-menu">
            <li><Link className="text-white " to="/active" > Trip Dashboard</Link></li>
            <li><Link className="text-white " to="/vehicle">Vehicle Availability</Link></li>
            <li><a className="text-white " href="https://auth.bi.cargodash.in/">Vehicle Availability</a></li>
          </ul>
        </span>
        <span className="cls" onClick={toggleMenu}></span>
      </div>
    <div className="collapse navbar-collapse justify-content-center p-1" id="navbarNav">
    <img width='100px' src="https://www.phantom2me.com/wp-content/uploads/2018/10/new-logo-new1.png" alt='logo'/>
    </div>
  </div>
</nav>
     
      
      
    
  );
}

export default Navbar;