import React,{ useContext, useEffect, useState } from "react";
import "./App.css";
import Card from "./Card";
import { AppSettings } from "./App";
import alarm1 from './alarm1.mp3';
import alarm2 from './alarm2.mp3';
import socketIOClient from 'socket.io-client';
import Loader from "./Loader";
const endpoint = 'http://apiv2.phantom2me.com';
const socket = socketIOClient(endpoint);

function Active() {
 const context=useContext(AppSettings)
 const [audio1] = useState(new Audio(alarm1));
 const [audio2] = useState(new Audio(alarm2));
 const playAudio = (audio) => {
  
  if(window.location.pathname=='/active'){
    audio.currentTime = 0;
    audio.play();
  } 
  
};
useEffect(()=>{
  

  socket.on('endTimeReached', (newData) => {
    playAudio(audio1);
    context.setYellowOne(state=>[...state,newData.id])
    context.setArr(state=>[...(new Set([newData,...state]))])
    fetch('http://apiv2.phantom2me.com/getsnoozed')
  .then(res=>res.json())
  .then(data=>context.setSnoozedArr(data)
)

   });  },[])

   useEffect(()=>{
 
    socket.on('newTrip', (newData) => {
      context.setArr(state=>[...(new Set([...newData,...state]))]) 
    fetch('http://apiv2.phantom2me.com/vehicletype', {headers: {
      'Authorization': '87b75c-109bef-b62f89-39b399-3646cc'
    }})
    .then(res=>res.json())
    .then(data=>data.map(item=>context.setVehicleType(state=>({...state,[item.id]:item.vehicletypename}))))   
     playAudio(audio2);   
     });  },[])
  return (
       context.arr? <div className="container mb-5">
        <div className=" row-cols-lg-3 m-1 row justify-content-between">
          {context.arr && context.arr.map((item) => (
            <Card key={item.id} id={item.id} lrNum={item.lr_no} vehicleNumber={item.vehiclename} customer={item.partyname} dateTime={item.pickup_datetime} tripDuration={item.expected_trip_duration} pickup={item.pickup} delivery={item.delivery} hub={item.hub} color='orange' stat={item.booking_status} code='1' wasSnoozed={item.wasSnoozed} added_Date={item.added_date} pulse={(new Date().getTime() - new Date(item.added_date).getTime())/(1000 * 60 * 60)<0.15}/>
          ))}
        </div>
      </div>:<Loader/>
  );
}

export default Active;