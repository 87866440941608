import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GoogleMap , useJsApiLoader, MarkerF, Autocomplete, Marker, Circle, Polyline, InfoWindow, InfoBox } from '@react-google-maps/api';
import { AppSettings } from './App';
import icon1 from './icontruck.png'
import icon2 from './icontruck2.png'
import icon3 from './icontruck3.png'
const Map = ({green,yellow,red,centerVehi,distanceOrganizer}) => {
  const mapContainerStyle = { height: '90%', width: '100%' };
  const options = {
    mapId: 'a60c3108eaed3427'
  };
  const [libraries] = useState(['places'])
const [radius,setRadius]=useState(50);
const context=useContext(AppSettings)
const [mapCenter, setMapCenter] = useState({ lat: 23.473324, lng: 77.947998 });
const[address,setAddress]=useState()
const[distance,setdistance]=useState()
const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDHipcElyoTj2x4U_KgIJ-PMgzVL73euM0",
    libraries
  }) 
const[clickedVehi,setClickedVehi]=useState({lat:0,lng:0})
const[isOpen,setOpen]=useState(false)
const mapRef = React.useRef();
  const circleRef = React.useRef();
  const geocodeAddress =async (e) => {
    setClickedVehi()
    setOpen(false)
    setAddress(e.target.value);
   if(e.target.value){ new window.google.maps.Geocoder().geocode( {address: e.target.value}, function(results, status) {
      if (status == 'OK') {
        context.setGeoCent({lat:results[0].geometry.location.lat(),lng:results[0].geometry.location.lng()})
        distanceOrganizer({lat:results[0].geometry.location.lat(),lng:results[0].geometry.location.lng()},radius);

      } else  {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });}
  };
  // Calculate the average latitude and longitude of the markers
  // Set the map center to the average location
  const handleMapLoad = (map) => {
mapRef.current=map;    // var bounds = new window.google.maps.LatLngBounds();
    // for (var i = 0; i < marker.length; i++) {
    //  bounds.extend(marker[i]);
    // }
   
    // map.fitBounds(bounds);
  };
    
const customIcon = {
    url:icon1,
    anchor: { x: 25, y: 25 },
    origin: { x: 0, y: 0 },
    rotation: 50,

   };
   const customIcon2 = {
    url:icon2,
    anchor: { x: 25, y: 25 },
    origin: { x: 0, y: 0 },
    rotation: 50,

   };
   const customIcon3 = {
    url:icon3,
    anchor: { x: 25, y: 25 },
    origin: { x: 0, y: 0 },
    rotation: 50,

   };
function handleDistance(){
  console.log('double')
  new window.google.maps.DistanceMatrixService().getDistanceMatrix(
  {
    origins: [{lat:context.geoCent.lat,lng:context.geoCent.lng}],
    destinations: [clickedVehi],
    travelMode: 'DRIVING'
  }, (response, status)=>setdistance(response.rows[0].elements[0].distance.text));
   
}  
const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 5
  
}

const zoomLevel = React.useMemo(() => {
 
  if (mapRef.current && circleRef.current) {
    const distance = radius * 1000 // 150 km in meters
      const northEast = new window.google.maps.geometry.spherical.computeOffset(
        {lat:context.geoCent.lat,lng:context.geoCent.lng},
        distance,
        45
      )
      const southWest = new window.google.maps.geometry.spherical.computeOffset(
        {lat:context.geoCent.lat,lng:context.geoCent.lng},
        distance,
        225
      )
      const bounds = new window.google.maps.LatLngBounds(southWest, northEast)
      mapRef.current.fitBounds(bounds)
  }
  return 5;
}, [context.geoCent,radius]);
  
  return isLoaded && (
    
        <>
        <div className='row col-md-10 justify-content-between m-1'>
          <div className='col-md-8 row'>
        <Autocomplete options={{ componentRestrictions: { country: "in" }}} className='col-md-10'>

<input type='search' className='form-control col-md-12' onChange={(e)=>setAddress(e.target.value)} onBlur={(e)=>geocodeAddress(e)} onKeyDownCapture={(e)=>{if(e.key == "Enter" )geocodeAddress(e)}}/>
        </Autocomplete>
        <select value={radius} onChange={(e)=>{setRadius(Number(e.target.value));context.geoCent && distanceOrganizer(context.geoCent,Number(e.target.value));}} className='col-md-2 p-0'>
        <option value='50'> 50 KM</option>
        <option value='100'> 100 KM</option>
        <option value='150'>150 KM</option>
        <option value='200'> 200 KM</option>
        <option value='300'> 300 KM</option>
        <option value='500'>500 KM</option>
      </select>
        </div>
        <button className='bi bi-arrow-clockwise h4 text-white bg-transparent col-md-1 mb-0' onClick={()=>window.location.reload()}></button>
        </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={context.geoCent?context.geoCent:mapCenter}
        zoom={zoomLevel}
        ref={mapRef}
        options={options}
        onLoad={handleMapLoad}
        onUnmount={()=>context.setGeoCent(null)}
      >
         
    
         <Polyline
      path={[context.geoCent,clickedVehi]}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: isOpen}}
    />
    
        <Circle
        ref={circleRef}
                center={context.geoCent}
                radius={Number(radius)*1000}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.6,
                  strokeWeight: 2,
                  fillColor: '#FF0000',
                  fillOpacity: 0.35,
                }}
                
              /> 
        <Marker  position={centerVehi}   animation={2}  />
          { red.map(item=>(
                      <MarkerF  position={item} icon={customIcon }  title={item.vehiclename} onClick={()=>{
                        setClickedVehi(item);setOpen(true);}}/>

          )
            )} 
          
          {context.geoCent && isOpen && (<InfoBox
      position={clickedVehi}
      onPositionChanged={handleDistance}
      options={{ closeBoxURL: '', enableEventPropagation: true }}
>
      <div style={{ backgroundColor: 'black',  padding: 10 ,width:'90px',borderRadius:'15px' }} onClick={()=>setOpen(false)}>
        <div style={{ fontSize: 16, color: 'white' }}>
          {distance}
        </div>
      </div>
    </InfoBox>)} 
       
        </GoogleMap>
        </>
  );
};

export default Map;

