import React, { useState, useEffect, useContext } from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Navigate } from "react-router";
import { AppSettings } from "./App";

const Card = ({
  lrNum,
  vehicleNumber,
  id,
  customer,
  dateTime,
  tripDuration,
  pickup,
  delivery,
  hub,
  color,
  stat,
  code,
  added_Date,
  wasSnoozed,
  pulse
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showRemarks, setShowRemarks] = useState(false);
  const [images, setImages] = useState([]);
  const context = useContext(AppSettings);
  const [selectedTime, setSelectedTime] = useState();
  const[isPulse,setPulse]=useState(true)
  const status = [
    "Pending",
    "Approved",
    "Rejected",
    "LR Created",
    "Challan Created",
    "On Trip",
    "Trip Ended",
    "Unloading Created",
    "Ready For Invoice",
    "Invoiced",
  ];
  const handleTimeChange = (moment) => {
    setSelectedTime(moment._d);
    console.log(new Date(moment._d).getMinutes());
  };
  const [hours, minutes, seconds] = tripDuration.split(":");
  const time = new Date(dateTime);
  time.setHours(time.getHours() + Number(hours));
  time.setMinutes(time.getMinutes() + Number(minutes));
  const [remark, setRemark] = useState("");
  // function to handle file input change
  const handleFileInputChange = (event) => {
    // const file = event.target.files[0];
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("id", id);
    fetch("http://apiv2.phantom2me.com/upload", {
      method: "POST",

      body: data,
    })
      .then((response) => response.json())
      .then((data) => context.lengthFinder())
      .catch((error) => {
        console.error("Error:", error);
      });
  };
function pulseSetter(){
  setPulse(false)
}
  const handleShowModal = () => {
    fetch("http://apiv2.phantom2me.com/getimages")
      .then((res) => res.json())
      .then((data) => {
        setImages(
          data
            .filter((item) => item.id == id)[0]
            .history.map((item) => {
              return {
                file: `http://apiv2.phantom2me.com/${item.filename}`,
                time: item.date,
              };
            })
        );
      });

    setShowModal(true);
  };
  // useEffect(() => {
  //   fetch("http://apiv2.phantom2me.com/getremarks")
  //     .then((res) => res.json())
  //     .then((data) =>
  //     (data.filter((item) => item.id == id).length)>0 && setRemark(data.filter((item) => item.id == id)[0].remark )      );
  // }, []);
  const handleShowRemarks = () => {
    fetch("http://apiv2.phantom2me.com/getremarks")
      .then((res) => res.json())
      .then((data) =>
      (data.filter((item) => item.id == id).length)>0 && setRemark(data.filter((item) => item.id == id)[0].remark )
      );

    setShowRemarks(true);
  };

  const removeCompleted = (e) => {
    context.setArr((state) => [
      ...context.completed.filter((item) => item.id == e.target.value),
      ...state,
    ]);
    fetch("http://apiv2.phantom2me.com/deletecompleted", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    context
      .setCompleted(
        context.completed.filter((item) => item.id != e.target.value)
      )

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const removeSnoozed = (e) => {
    context.setArr((state) => [
      ...context.snoozedArr.filter((item) => item.id == e.target.value),
      ...state,
    ]);
    fetch("http://apiv2.phantom2me.com/deletesnoozed", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    context
      .setSnoozedArr(
        context.snoozedArr.filter((item) => item.id != e.target.value)
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseRemarks = () => {
    fetch("http://apiv2.phantom2me.com/addremark", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        remark: remark,
      }),
    }).catch((error) => {
      console.error("Error:", error);
    });
    setShowRemarks(false);
  };

  const handleCompleted = (e) => {
    let newOne = context.arr.filter((item) => item.id == e.target.value);
    context.setArr(context.arr.filter((item) => item.id != e.target.value));
    fetch("http://apiv2.phantom2me.com/completed", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        lr_no: lrNum,
        vehiclename: vehicleNumber,
        partyname: customer,
        pickup_datetime: dateTime,
        expected_trip_duration: tripDuration,
        pickup: pickup,
        delivery: delivery,
        hub: hub,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        context.setCompleted((state) => [...newOne, ...state]);

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSnooze = (e) => {
    if (
      new Date(selectedTime).getMinutes() ||
      new Date(selectedTime).getHours()
    ) {
      context.setArr(context.arr.filter((item) => item.id != e));
      context.setSnoozedArr((state) => [
        ...state,
        ...context.arr.filter((item) => item.id == e),
      ]);
      if (id) {
        fetch("http://apiv2.phantom2me.com/addItem", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            lr_no: lrNum,
            vehiclename: vehicleNumber,
            partyname: customer,
            pickup_datetime: dateTime,
            expected_trip_duration: tripDuration,
            pickup: pickup,
            delivery: delivery,
            hub: hub,
            booking_status: stat,
            endTime: selectedTime,
            wasSnoozed:true
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // window.location.href='http://localhost:3000/'
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };
  function renderInput(props, openCalendar, closeCalendar) {
    return (
      <div>
        <button
          className=" border-0 col-md-1  bg-transparent"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Snooze"
          onClick={openCalendar}
          value={id}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="white"
            className="bi bi-alarm"
            viewBox="0 0 16 16"
          >
            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"></path>
            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"></path>
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className={`card ${pulse && isPulse?'pulse':''}`} id={id} onClick={pulseSetter}  style={{border:`${(new Date().getTime() - new Date(added_Date).getTime())/(1000 * 60 * 60)>1 || !added_Date ?(context.yellowOne.includes(id)?1:0):2}px solid ${context.yellowOne.includes(id)?'yellow': 'green'}`}}>
      <div className="card-top-part text-white mt-1">
        <div className="left-part lh-1">
          <div className="user-name ">
            <p className="card-text p-0 m-0 ">{vehicleNumber}</p>
            <p className={`role bg-${color} p-1 m-1`}> {id} </p>
            <span className="me-auto fs-6">{"(" + lrNum + ")"}</span>
            <span className=" text-end">
              {status[context.bookingStatus[id]]}
            </span>
          </div>
          <div className="user-position  ">
            <p className="card-text   mb-1 ">{customer}</p>

            <p className="card-text  d-flex justify-content-between pb-0 m-0">
              {new Date(dateTime).toLocaleString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
              })}

              <p className="p-0 m-0">
                ETD:
                {new Date(time).toLocaleString("en-GB", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: false,
                })}
              </p>
            </p>

            <p className="card-text p-0 m-0 ">
              Vehi. Type: {context.vehicleType[id]}
            </p>
          </div>
        </div>
      </div>
      <div className="card-bottom-part ">
        {code == 1 && (
          <div className="bottom-part">
            <DateTime
              renderInput={renderInput}
              onChange={handleTimeChange}
              dateFormat={false}
              timeFormat="HH:mm"
              onClose={() => handleSnooze(id)}
            />
          </div>
        )}
        <div className="bottom-part ">
          <button
            type="button"
            className="border-0 bg-transparent text-white"
            onClick={handleShowModal}
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="History"
          >
            <i className="bi bi-clock-history"></i>{" "}
            {context.counting[id] ? context.counting[id] : ""}{" "}
          </button>
          <div
            className={`modal fade ${showModal ? "show d-block" : ""}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden={!showModal}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    History- {id}, LR-{lrNum}, Vehicle-{vehicleNumber}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {images.map((image, index) => (
                    <>
                      <p className="pb-0 mt-2 mb-0">
                        {new Date(image.time).toLocaleString("en-US", {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </p>
                      <img
                        key={index}
                        src={image.file}
                        width="100%"
                        alt={`Uploaded image ${index}`}
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {(code == 1 || code == 2) && (
          <div className="bottom-part">
            <input
              type="file"
              accept="image/*"
              className="custom-file-input col-md-1 "
              id={`customFile${id}`}
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
            <label
              className="custom-file-label col-md-1 text-white"
              htmlFor={`customFile${id}`}
              style={{ cursor: "pointer" }}
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Upload"
            >
              <i className="bi bi-file-earmark-arrow-up"></i>
            </label>{" "}
          </div>
        )}

        {code == 1 && (
          <div className="bottom-part">
            <button
              className=" border-0 col-md-1 bi bi-check-lg bg-transparent text-white"
              onClick={handleCompleted}
              value={id}
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Completed"
            ></button>{" "}
          </div>
        )}
        <i
          className="btn border-0 bi bi-info-lg bg-transparent text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title={
            "PICKUP: " +
            pickup +
            "\n\n" +
            "DELIVERY: " +
            delivery +
            "\n\n" +
            "HUB: " +
            hub
          }
        ></i>
        {code == 3 && (
          <button
            type="button"
            className="btn bi bi-x-lg bg-transparent text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="delete"
            onClick={removeCompleted}
            value={id}
          ></button>
        )}
        {code == 2 && (
          <button
            type="button"
            className="btn bi bi-x-lg bg-transparent text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="delete"
            onClick={removeSnoozed}
            value={id}
          ></button>
        )}
        <button
          type="button"
          className="border-0 bg-transparent text-white"
          onClick={handleShowRemarks}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Remarks"
        >
          <i className="bi bi-card-text"></i>{" "}
        </button>
        <div
          className={`modal fade ${showRemarks ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showRemarks}
          id={id}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Remarks
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseRemarks}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <textarea
                className="form-control  m-2"
                id="fixed-width-textarea"
                placeholder="Enter your text here..."
                style={{ width: "95%", height: "200px" }}
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
